.textArea{
    width: 90%;
   
}

.titleText {
  height: 50px !important;
}

.titleArea {
  width: 82%;
  height: 1px;
}

.text2 {
  height: 120px !important;
}

.textArea2{
   width: 82%;
   height: 50px;
}

.MuiFormLabel-root {
    font-size: 20px !important;
}

.buttonSend {
 float: right;
 margin-top: -60px;
 margin-right:  20px;
}

.buttonBuscar {
  margin-top: 40px;
  margin-left: 25px;
}

.tamañoModal {
  height: '500%'
}

.listado {
  height: '700%';
}

.vl {
  margin-left: 20%;
  margin-top: -150px;
  border-left: 2px solid blue;
  height: 90%;
  float: left;
}

.vlTutor {
  margin-left: 20%;
  margin-top: -150px;
  border-left: 2px solid blue;
  height: 100%;
  float: left;
}

.vlImage {
  margin-left: 140%;
  margin-top: -115px;
  float: left;
}

.vlImageTutor {
  margin-left: 140%;
  margin-top: -10px;
  float: left;
}

.buttonSendData {
  margin-inline-end: auto;
}

.alumno {
  font-weight: bold;
  color: aqua;
}

.padrefamilia {
  font-weight: bold;
  color: aqua;
}

.alumnoName{
  font-weight: bold;
  color: white;
}

.padrefamiliaName{
  font-weight: bold;
  color: white;
}

#background {
  border: 5px solid white;
  padding: -1px;
  background: url(../../public/img/primary.png);
  background-repeat: no-repeat;
  background-size: 100% 110px;
  
}

.buttonmui {
  justify-content: end; 
  align-content: flex-end;
}

.form-group1 {
  width: '05%';
}

.form-control{
  width: '05%';
}

.select{
  height: '10%';
}

.Turno{
  color: white;
  
}

.containerImage {
  border: 2px solid blue;
  border-radius:  8px;
  margin-top: -57%;
  margin-left: 65%; 
  display: inline-block;
  padding:5px;
  box-sizing: border-box;
  float:left;
}
.sizeImageA{
  width:  250px;
  height: 200px;
}

.containerImageTutor {
  border: 2px solid blue;
  border-radius:  8px;
  margin-top: -57%;
  margin-left: 65%; 
  display: inline-block;
  padding:5px;
  box-sizing: border-box;
  float:left;
}
.sizeImageT{
  width: 250px;
  height: 200px;
}

img {
  object-fit: fill;
  width:100%;
  height:100%;
}

/* Invisible texto */
figcaption {
  display:none; 
  transition: all .5s;
  float: right;
  margin-top: -283px;
  margin-right: -12px;
}
/* Visible texto */
figure:hover > figcaption {
  display:block;
  transition: all .5s;
  border: 1px solid #e61363;
  border-radius: 3px;
  padding: 2px 6px;
}

.selectContainer {
  border: 2px solid blue;
  border-radius:  8px;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2%;
}

.vlImage2 {
  margin-right: 08%;
  margin-top: -380px;
  border-left: 2px solid blue;
  float: right;
  
}

.buttonsGuardar{
  margin-top: 4em;
}

.inputfile{
  display: "none"
}

.cargarImagen {
  margin-left: 903px;
  margin-top: -07%;
}

.switch {
  margin-left: 926px;
  margin-top: 10px;
}

.vlImage3 {
  margin-right: 10%;
  margin-top: -360px;
  border-left: 2px solid blue;
  float: right;

}

.containerImage2 {
  border: 2px solid blue;
  border-radius:  8px;
  margin-top: -60%;
  margin-left: 65%; 
  display: inline-block;
  padding:5px;
  box-sizing: border-box;
  float:left;
}
.sizeImageB{
  width:  250px;
  height: 200px;
}

.cargarImagen2 {
  margin-left: 883px;
  margin-top: 03%;
}

.enviarMensaje{
  font-weight: bolder;
  color: white;
  margin-top: -15%;
  margin-left: 30%;
}
.logoContainer{
  width: 20%;
  margin-top: -20px;
}
.img-responsiveModal{
  object-fit: cover;
}
.buttonClear{
  float: left;
  margin-left: 65%;
  margin-top: -41px;
}
